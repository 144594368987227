.colorGreen {
  color: green;
}
.text {
  color: white;
}

.iconColor {
  color: #fff;
}
